import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import H6 from '@material-tailwind/react/Heading6';

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState('-left-64');
    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    {/* <a
                        href="https://material-tailwind.com?ref=mtd"
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 text-center w-full inline-block"
                    >
                        <H6 color="gray">Material Tailwind</H6>
                    </a> */}
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                            {/* <li className="rounded-lg mb-4">
                                <NavLink
                                    to="/"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Dashboard
                                </NavLink>
                            </li> */}
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/settings"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="settings" size="2xl" />
                                    Settings
                                </NavLink>
                            </li> */}
                            {/* <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/tables"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="toc" size="2xl" />
                                    Tables
                                </NavLink>
                            </li> */}
                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/addevent"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   Add Event
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/addbill"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   Add Bill
                                </NavLink>
                            </li>

                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/viewbill"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   View Bill
                                </NavLink>
                            </li>
                            {localStorage.getItem('role')!=="user" &&<li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/viewallbill"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   View All Bill
                                </NavLink>
                            </li>}
                            {localStorage.getItem('role')!=="user" &&<li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/paidbill"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   Paid Bill
                                </NavLink>
                            </li>}
                            {localStorage.getItem('role')!=="user" &&<li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/unpaidbill"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   Pending Bill
                                </NavLink>
                            </li>}
                            {localStorage.getItem('role')!=="user" &&<li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/pendingbill1stLevel"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                    1st Approver pending bill
                                </NavLink>
                            </li>}
                            {localStorage.getItem('role')!=="user" &&<li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/pendingbill12ndLevel"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                    2nd Approver pending bill
                                </NavLink>
                            </li>}
                            {localStorage.getItem('role')!=="user" &&<li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/urgentBill"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="event" size="2xl" />
                                   Urgent Bill
                                </NavLink>
                            </li>}


                            
                            
                            
                            <li className="rounded-lg mb-2 text-gray-700">
                                <div
                                 to="ww"
                                    className="flex flex-col items-center gap-4 text-sm text-black font-bold uppercase font-light px-4 py-3 rounded-lg"
                                  
                                >
                                    <Icon name="people" size="2xl" />
                          <b> Logged in as    {localStorage.getItem('name')}</b> 
                         
                          <b> Access Level   {localStorage.getItem('role')}</b>


                          <b className='bg-red-500 text-white p-2 rounded-md text-xs ' onClick={()=>(localStorage.clear(),window.location.href = "/")}> LOGOUT </b>
                          
                                </div>
                            </li>
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/login"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="fingerprint" size="2xl" />
                                    Login
                                </a>
                            </li> */}
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/register"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="list_alt" size="2xl" />
                                    Register
                                </a>
                            </li> */}
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/landing"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="web" size="2xl" />
                                    Landing Page
                                </a>
                            </li> */}
                            {/* <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="account_circle" size="2xl" />
                                    Profile Page
                                </a>
                            </li> */}
                        </ul>

                    </div>
                </div>
            </div>
        </>
    );
}

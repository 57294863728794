import { Card } from "@material-tailwind/react";
import { CardHeader } from "@material-tailwind/react";
import { CardBody } from "@material-tailwind/react";
import Image from "@material-tailwind/react";
import Progress from "@material-tailwind/react";
import Team1 from "assets/img/team-1-800x800.jpg";
import Team2 from "assets/img/team-2-800x800.jpg";
import Team3 from "assets/img/team-3-800x800.jpg";
import Team4 from "assets/img/team-4-470x470.png";
import { data } from "autoprefixer";
import { Button } from "@material-tailwind/react";
import { Modal } from "@material-tailwind/react";
import { ModalHeader } from "@material-tailwind/react";
import { ModalBody } from "@material-tailwind/react";
import { ModalFooter } from "@material-tailwind/react";
import { useState } from "react";
import { saveAs } from "file-saver";
import { Label } from "@material-tailwind/react";
import { CardRow } from "@material-tailwind/react";
import { Chip } from "@material-tailwind/react";
import { CardStatus } from "@material-tailwind/react";
import CardStatusFooter from "@material-tailwind/react";
import { Icon } from "@material-tailwind/react";
import ClosingLabel from "@material-tailwind/react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

import gifimg from '../assets/img/circle.gif'

import { IconButton } from "@material-tailwind/react";
export default function CardTable(props) {
const { id } = useParams(); // id will be 'amazon'

  console.log("rops.mainpagemainpagemainpagemainpage",props.data);
  const [showModal, setShowModal] = useState(false);
  const [showModal22, setShowModal22] = useState(false);

  const [showModalCode, setShowModalCode] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [dataItem, setDataItem] = useState({});
  const [operation, setOperation] = useState(localStorage.getItem('role'));



const dltBill=async (id)=>{
  
  let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/dltbill", { id:id });

  if (res.status == 200) {
    setShowModal22(true)

    setTimeout(() => {
     
       window.location.reload();
    }, 2000);

  
  }
}

const  datcal=(data)=>{
  
  var timestamp = parseInt(data)
var date = new Date(timestamp);

console.log("Date: "+date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear()+
          " "+date.getHours()+
          ":"+date.getMinutes()+
          ":"+date.getSeconds());

          return (date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear())
}

  const downloadImage = (imgUrl) => { 

    // download="AwesomeImage.png"
    saveAs(imgUrl, "bill.jpg"); // Put your image url here.
  };
  useEffect(() => {
   
  }, [showModalCode]);

  const markAsPaid = async (paid,status) => {
    let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/paidbill", { id: paid ,status :status});
    if (res.status == 200) {
      // window.location.reload();
      setShowModal22(true)

      setTimeout(() => {
       
         window.location.reload();
      }, 2000);
      setShowModalCode(!showModalCode);
    }
  };

  const approvedManager = async (paid,status) => {
    let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/appmanager", { id: paid ,status :status});
    if (res.status == 200) {
      // window.location.reload();
      setShowModal22(true)
      setShowModalCode(!showModalCode);
      setTimeout(() => {
       
        window.location.reload();
     }, 2000);
    }
  };
  const approvedAsstManager = async (paid,status) => {
    let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/appassttmanager", { id: paid,status :status});
    if (res.status == 200) {
      // window.location.reload();
      setShowModal22(true)
      setShowModalCode(!showModalCode);
      setTimeout(() => {
       
        window.location.reload();
     }, 2000);
    }
  };
  const download = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  var timestamp = 1652436883894
  var date = new Date(timestamp);
  console.log(date)
  console.log(date)
  const fruits = [{id:"aanana"},{id: "azaaOange"}];
  console.log(
  fruits.reverse())
  
  let dtaa= props.data&&props.data.billList&&props.data.billList.reverse()
  console.log(dtaa)
  return (
    <>
    {/* {alert(date.getMonth())} */}
   {window&& window.location.pathname!=="/viewallbill" &&<> 
   <div className="text-center text-3xl mb-1 text-white">Event Name : {id} </div>
    <div className="text-center text-3xl mb-5  text-white">Event code : {localStorage.getItem('eventCode')} </div></>}
      <div className="flex m-2 items-center justify-center">
        
   
        
        <div className="w-3/12 m-2">
  
          
          <Card>
            <CardRow>
              <CardHeader color="lightBlue" size="lg" iconOnly>
                <Icon name="wallet" size="5xl" color="white" />
              </CardHeader>

              <CardStatus
                style={{ fontSize: "2rem" }}
                size="5xl"
                title="Total Bill"
                amount={props.data.totalBill}
              />
            </CardRow>

            <Button
              color="blue"
              buttonType="filled"
              size="lg"
              rounded={false}
              block={true}
              iconOnly={false}
              ripple="light"
            >
              Total Bill
            </Button>
          </Card>
        </div>
        <div className="w-3/12 m-2">
          
          <Card>
            <CardRow>
              <CardHeader color="lightBlue" size="lg" iconOnly>
                <Icon name="close" size="5xl" color="white" />
              </CardHeader>

              <CardStatus title="Paid" amount={props.data.paid} />
            </CardRow>

            <Button
              color="green"
              buttonType="filled"
              size="lg"
              rounded={false}
              block={true}
              iconOnly={false}
              ripple="light"
            >
              PAID
            </Button>
          </Card>
        </div>
        <div className="w-3/12 m-2">
          
          <Card>
            <CardRow>
              <CardHeader color="lightBlue" size="lg" iconOnly>
                <Icon name="groups" size="5xl" color="white" />
              </CardHeader>

              <CardStatus title="Unpaid" amount={props.data.totalUnpaid} />
            </CardRow>

            <Button
              color="red"
              buttonType="filled"
              size="lg"
              rounded={false}
              block={true}
              iconOnly={false}
              ripple="light"
            >
              UNPAID
            </Button>
          </Card>
        </div>
      </div>

      {props.data &&
                  props.data.billList &&
                  props.data.billList.map((item, index) => {return   item.vendorName.toLowerCase().includes(props.nameSearch.toLowerCase()) && item.billStatusManager==="false" &&( <Card className=" my-2">
   
      <div className="flex items-center justify-center  ">
      <div className="flex flex-col w-3/12">
<div className="text-xs font-bold text-center text-black-500 text-base">Event  date {item.billStatus}	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">{item.sEventDate}</div>

      </div>
    <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Event  name	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500"> {item.eventName}</div>

      </div>
    <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Vendor Name	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">{item.vendorName}</div>
      </div>
    <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Category	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">{item.category}</div>
      </div>
      <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Total Bill	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">{item.amount}</div>
      </div>
    
    

      </div>
      <div className="flex items-center justify-between  my-3 ">
       <div className="flex flex-col w-3/12">
<div className="text-xs font-bold text-center text-black-500 text-base">Upload Date	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">    {datcal(item.uploadData)}</div>

      </div>
       <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Upload By  	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">  {item.addedBy}</div>

      </div>
       <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Bill Cycle	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">{item.billcycle}</div>
      </div>
       <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Advance Paid	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500">{item.advance}</div>
      </div>
      <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Due date	</div>
<div className=" font-semibold text-center mt-1 text-gray-500" style={{fontSize:'14px'}}> {datcal(item.dueDate)}</div>
      </div>
    
    

      </div>
      <div className="flex items-center justify-between  my-3 ">
       <div className="flex flex-col w-3/12 flex items-center justify-center">
      <div className="text-xs font-bold text-center text-black-500 text-base">View Bill  	</div>


<div 
                          className="mt-2 text-xs text-center w-6/12 bg-blue-400 text-white p-1 rounded-xl mt-3 font-semibold cursor-pointer"
                          
                           
                            // buttonType="filled"
                            // size="small"
                            onClick={() => (
                                setShowModal(true),
                                setImgUrl(item.imagurl),
                                setDataItem(item)
                                // setOperation("billApproved")
                              )}
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            // disable={true}
                            ripple="light"
                          >
                            View Bill
                          </div>

      </div>
       <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">1st Approver  	</div>
      <div className="row flex items-center justify-center cursor-pointer mt-2 " >
                          <Button
                          style={{width:'40%'}}
                            color={item.billStatusAsstManager==="true"?"green":'red'}
                            buttonType="filled"
                            size="regular"
                          
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                         {item.billStatusAsstManager==="true"?"DONE":'PENDING'}
                          </Button>
                          </div>

      </div>
       <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base" >2nd Approver	</div>
      <div className="row flex items-center justify-center text-xs mt-2" >
                          <Button
                          style={{width:'40%'}}
                            color={item.billStatusManager==="true"?"green":'red'}
                            buttonType="filled"
                            size="regular"
                          
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                         {item.billStatusManager==="true"?"Done":'Pending'}
                          </Button>
                          </div>
      </div>
       <div className="flex flex-col w-3/12">
      <div className="text-xs font-bold text-center text-black-500 text-base">Bill Status 	</div>
      <div className="row flex items-center justify-center text-xs mt-2 " >
                          <Button
                          style={{width:'40%'}}
                            color={item.billStatus==="true"?"green":'red'}
                            buttonType="filled"
                            size="regular"
                          
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                         {item.billStatus==="true"?"Done":'Pending'}
                          </Button>
                          </div>
      </div>
      <div className="flex flex-col w-3/12">
      <div className="font-bold text-center text-black-500 text-base">Event Code	</div>
<div className="text-xs font-semibold text-center mt-1 text-gray-500"> {item.eventCode} </div>
      </div>
    
    

      </div>

    

      
        </Card>)
                  }
                  )
      }

        

     {false && <Card>
        {/* <CardHeader color="purple" contentPosition="center">
                <div className='flex items-center justify-evenly w-full bg-red'>
                <h2 className="text-white text-center text-2xl">Bill Table</h2>
                <h2 className="text-white text-center text-2xl">Bill Table</h2>
                <h2 className="text-white text-center text-2xl">Bill Table</h2>
                </div>
            </CardHeader> */}
        <CardBody>
          <div className="overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>

                <tr>
                <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">Event name</th>
                <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">Event date</th>

                {/* <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">Event Date</th> */}

                {/* <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">Event Date</th> */}
                {/* <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">event Name</th> */}

                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">Vendor Name</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">Prod Category</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">Upload Date</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">Upload By</th>

                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">View Bill </th>
                  <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">Bill cycle</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">Total Bill</th>


                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">Advance Paid</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">1st  Aprrover</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs ">2nd Approver</th>
                  <th className=" w-[10%]  text-purple-900 border-gray-400 border p-2 text-xs "> Status </th>
                  <th className=" w-[5%]  text-purple-900 border-gray-400 border p-2 text-xs ">Due Date</th>
                

                 
                </tr>
              </thead>
              <tbody>
                {console.log(props.data &&
                  props.data.billList && props.data.billList.reverse())}
                {false && props.data &&
                  props.data.billList &&
                  props.data.billList.map((item, index) => {
                    return (
                      <tr className="border-b">
                      <th className=" w-[5%] text-black-500  text-center row items-center justify-center text-xs " >
                          {item.sEventDate}
                        </th>
                      <th className=" w-[5%] text-black-500  text-center row items-center justify-center text-xs" >
                          {item.eventName}
                        </th>
 
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center text-xs ">
                          {item.vendorName}
                        </th>
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center text-xs ">
                          {item.category}
                        </th>
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center text-xs ">
                          {datcal(item.uploadData)}
                        </th>
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center text-xs ">
                          {item.addedBy}
                        </th>
                               
                        <div className=" text-black-500  text-center row items-center justify-center text-xs " >
                          <div 
                          className="text-xs bg-blue-400 text-white p-1 rounded-xl mt-3 font-semibold cursor-pointer"
                          
                           
                            // buttonType="filled"
                            // size="small"
                            onClick={() => (
                                setShowModal(true),
                                setImgUrl(item.imagurl),
                                setDataItem(item)
                                // setOperation("billApproved")
                              )}
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            // disable={true}
                            ripple="light"
                          >
                            View 
                          </div>
                          </div>
                        <th className="  w-[5%] text-black-500  text-center row items-center justify-center ">
                        <div className="row flex items-center justify-center  text-xs" >
                        {item.billcycle}
                          {/* {item.billcycle !== "true" ? (
                            <Label color="red">pending</Label>
                          ) : (
                            <Label color="green">Paid</Label>
                          )} */}
                          </div>
                        </th>
                        
                       
                        <th className="  w-[10%] text-black-500  text-center row row items-center justify-center text-xs ">
                        {item.amount}

                          {/* {item.imagurl} */}
                        </th>
                       
                        <th className="  w-[10%] text-black-500  text-center row row items-center justify-center text-xs ">
                        {item.advance}

                          {/* {item.imagurl} */}
                        </th>
                       
                     
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center  text-xs">
                         <div className="row flex items-center justify-center cursor-pointer " >
                          <Button
                          style={{width:'40%'}}
                            color={item.billStatusAsstManager==="true"?"green":'red'}
                            buttonType="filled"
                            size="regular"
                          
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                         {item.billStatusAsstManager==="true"?"D":'P'}
                          </Button>
                          </div>
                        </th>
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center ">
                         <div className="row flex items-center justify-center text-xs " >
                          <Button
                          style={{width:'40%'}}
                            color={item.billStatusManager==="true"?"green":'red'}
                            buttonType="filled"
                            size="regular"
                          
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                         {item.billStatusManager==="true"?"D":'P'}
                          </Button>
                          </div>
                        </th>

                        <th className=" w-[5%] text-black-500  text-center row  items-center justify-center p-4 ">
                          <div className="  flex  text-center row  items-center justify-center text-xs  ">
                            {item.billStatus !== "true" ? (
                            <Label color="red">pending</Label>
                          ) : (
                            <Label color="green">Paid</Label>
                          )}
                          </div>
                        </th>
                        <th className=" w-[10%] text-black-500  text-center row items-center justify-center p-4  text-xs">
                      
                        {datcal(item.dueDate)}
                        </th>
                        
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>}

      <div className="flex items-center justify-center">
        {/* <Button
                color="lightBlue"
                type="button"
                onClick={(e) => setShowModal(true)}
                ripple="light"
            >
                Open large Modal
            </Button> */}

        <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
          <ModalHeader toggler={() => setShowModal(false)}></ModalHeader>
          <ModalBody>
            <img
             
              className="flex items-center justify-center"
              src={imgUrl}
              
              download={true}
              alt=""
            />


          </ModalBody>
          <div className="flex flex-col items-center justify-center  bg-gray-200 rounded-lg">
            <div className="flex items-center justify-between w-full p-2">
              
            <div className="flex my-2">
              Bill Amount
              <span className="mx-1 font-bold">{dataItem.amount}</span>
            </div>
            <div className="flex my-2 ">
              event code 
              <span className="mx-1 font-bold">{dataItem.eventCode}</span>
            </div>
            <div className="flex my-2">
              Product Name
              <span className="mx-1 font-bold">{dataItem.productName}</span>
            </div>

           
            </div>
            <div className="flex items-center justify-between w-full p-2">
            <div className="flex my-2">
              Advance
              <span className="mx-1 font-bold">{dataItem.advance}</span>
            </div>
            <div className="flex my-2 ">
              Due  Date
              <span className="mx-1 font-bold">    {dataItem.dueDate=="immediately"? 'immediately': datcal(dataItem.dueDate)}</span>
            </div>
            <div className="flex my-2">
              Product Name
              <span className="mx-1 font-bold">{dataItem.productName}</span>
            </div>

           
            </div>
          </div>

          {operation=="finance" &&<div className="flex flex-col items-center justify-center shadow-xl mt-3">
            <ModalFooter className="shadow-xl">
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>

              <Button
                color="red"
                onClick={() => markAsPaid(dataItem._id,dataItem.billStatus!=="true"?'true':'false')}
                ripple="light"
              >
               
                {dataItem.billStatus!=="true"?' Mark As Paid':'Reject'}
              </Button>
            </ModalFooter>
          </div>}


          {operation=="subadmin" &&<div className="flex flex-col items-center justify-center shadow-xl mt-3">
            <ModalFooter>
              <Button
                color="yellow"
                onClick={() => dltBill(dataItem._id)}
                ripple="light"
              >
               Delete Bill
              </Button>
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>

              <Button
                color="red"
                onClick={() => approvedAsstManager(dataItem._id,dataItem.billStatusAsstManager!=="true"?'true':'false')}
                ripple="light"
              >
               
                {dataItem.billStatusAsstManager!=="true"?'Approve':'Reject'}
               
              </Button>
            </ModalFooter>
          </div>}

          {operation=="admin" &&<div className="flex flex-col items-center justify-center mt-3">
            <ModalFooter>
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>

              <Button
                color="red"
                onClick={() => approvedManager(dataItem._id,dataItem.billStatusManager!=="true"?'true':'false')}
                // onClick={() => approvedManager(dataItem._id)}
                ripple="light"
              >
              {dataItem.billStatusManager!=="true"?'Approve':'Reject'}
              </Button>
            </ModalFooter>
          </div>}

          {operation=="user" &&<div className="flex flex-col items-center justify-center">
            <ModalFooter>
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>

             
            </ModalFooter>
          </div>}
          
        </Modal>
      </div>


      <div className="flex items-center justify-center w-4/12">



    <Modal size="sm" active={showModal22} toggler={() => setShowModal22(false)}>
                <ModalHeader toggler={() => setShowModal22(false)}>
                 
                <h1 className="my-3"> Task  Done </h1>
                </ModalHeader>
                <ModalBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                       <img src={gifimg} alt="" />
                    </p>
                </ModalBody>
              
            </Modal> 
            </div>
    </>
  );
}



import React,{useState} from 'react'
import {Input} from "@material-tailwind/react";
import {Button} from "@material-tailwind/react";
import { Modal } from "@material-tailwind/react";
import { ModalHeader } from "@material-tailwind/react";
import { ModalBody } from "@material-tailwind/react";
import gifimg from '../assets/img/circle.gif'

import { ModalFooter } from "@material-tailwind/react";
import axios from 'axios'
export default function Addevent() {

  const [showModal, setShowModal] = useState(false);

  const [eventName,setEventName]=useState("")
  const [eventSDate,setEventSDate]=useState("")
  const [eventEDate,setEventEDate]=useState("")
  const [eventManager,setEventManager]=useState("")
  const [eventCode,setEventCode]=useState("")



 const  addEvent = async () => {
    let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/addevent",{
      userName:"sppl.it2@gmail.com",
      eventName:eventName,
      eventSDate:eventSDate,
      eventEDate:eventEDate,
      eventManager:eventManager,
      eventCode:eventCode
    });

    if(res.status==200 &&res.data._id!==null){


      setShowModal(true)

      window.location.reload();
    }
  
  
};
 

  return (
    <>
    <div className="flex items-center justify-center ">
        
        <div className="flex flex-col w-6/12 mx-3 items-center justify-cente">
        <div class="w-full bg-white rounded-xl overflow-hdden shadow-md p-4  text-center">
          <div class="p-4 ">
            <div class="p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-md rounded-full bg-red-500">
              <span class="material-icons text-white text-xl leading-none">
                stars
              </span>
            </div>
            <h1 class="text-gray-900 text-xl font-serif font-bold leading-normal mt-0 mb-4">
            <Input
            type="text"
            onChange={(e)=>setEventName(e.target.value)}
            color="lightBlue"
            value={eventName}
            size="regular"
            outline={true}
            placeholder="Event Name"
        />
            </h1>
            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-4 font-bold">
            <Input
            type="text"
            onChange={(e)=>setEventEDate(e.target.value)}
            value={eventEDate}

            color="lightBlue"
            size="regular"
            outline={true}
            placeholder="Start Date (DD/MM/YYYY)"
        />
             
            </div>

            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-4 font-bold">
            <Input
            type="text"
            onChange={(e)=>setEventCode(e.target.value)}
            value={eventCode}

            color="lightBlue"
            size="regular"
            outline={true}
            placeholder="Event Code"
        />
             
            </div>
            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-4 font-bold">
          <Input
            type="text"
            // onChange={(e)=>alert(e.target.value)}
            color="lightBlue"
            onChange={(e)=>setEventSDate(e.target.value)}
            size="regular"
            outline={true}
            value={eventSDate}
            placeholder="End Date  (DD/MM/YYYY)"
        />
             
            </div>

            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-4 font-bold">
          <Input
            type="text"
            // onChange={(e)=>alert(e.target.value)}
            color="lightBlue"
            size="regular"
            value={eventManager}
            onChange={(e)=>setEventManager(e.target.value)}
            outline={true}
            placeholder="Manager Name"
        />
             
            </div>
            <div class="flex items-center justify-center text-blue-gray-700 text-base leading-relaxed mt-0 mb-4 font-bold">
            <Button
            color="lightBlue"
            buttonType="filled"
            size="lg"
            onClick={()=>addEvent()}
            rounded={false}
            // block={false}
            // iconOnly={false}
            ripple="light"
        >
            submit
        </Button>
             
            </div>
           <h3>Only the hyphen symbol (-) can be used when entering the Event Name</h3>
          </div>
        </div>
        </div>
      
       
      </div>
       <div className="flex items-center justify-center w-4/12">



       <Modal size="sm" active={showModal} toggler={() => setShowModal(false)}>
                   <ModalHeader toggler={() => setShowModal(false)}>
                    
                 
                   </ModalHeader>
                   <ModalBody>
                   <h1 className="my-3 text-center flex item-center  text-2xl text-bold justify-center w-full"> Event Added </h1>
                       <p className="text-base leading-relaxed text-gray-600 font-normal">
                          <img src={gifimg} alt="" />
                       </p>
                   </ModalBody>
                 
               </Modal> 
               </div>
      </>
  )
}

import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import Dashboard from 'pages/Dashboard';
import Settings from 'pages/Settings';
import Tables from 'pages/Tables';
import Maps from 'pages/Maps';
import Footer from 'components/Footer';
import Addevent from './pages/addevent'
import AllEvent from './pages/allevent'
import EventDetail from './pages/eventdetail'
import ViewBill from './pages/viewbill'
import ViewAllBill from './pages/viewallbill'
// Tailwind CSS Style Sheet
import ViewBillDetail from './pages/viewbilldetail'
import Login from './pages/login'
import Reg from './pages/registraion'
import 'assets/styles/tailwind.css';
import Urgent from './pages/urgent'

import Paid from './pages/paidbill'
import UnPaid from './pages/unpaid'
import Pending1st from './pages/pending1stapp'
import Pending2nd from './pages/pending2ndapp'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'
import 'react-dates/lib/css/_datepicker.css';
function App() {
function add(){
    //  alert( window.location.pathname)
 if(!localStorage.getItem('role')&& window.location.pathname!=="/login" &&window.location.pathname!=="/reg"&&window.location.pathname!=="/")
 {
      window.location.href="/"
}
    // window.location.href = "http://foo.com/error.php";
}
add()
    
    // {localStorage.getItem('userId')!==null ? window.location="/login":null}
    return (
        <>
         <Route exact path="/reg" component={Reg} />
                 
                 
                 <Route exact path="/login" component={Login} />
                 <Route exact path="/" component={Login} />


          {localStorage.getItem('role')!==null &&  <Sidebar />}
            <div className="md:ml-64">
                <Switch>
                  
              
                {localStorage.getItem('role')!==null && <>  <Route exact path="/dash" component={Dashboard} />
                    <Route exact path="/addevent" component={Addevent} />
                    <Route exact path="/addbill" component={AllEvent} />
                    <Route exact path="/viewbill" component={ViewBill} />
                    <Route exact path="/urgentBill" component={Urgent} />

                    <Route exact path="/paidbill" component={Paid} />
                    <Route exact path="/unpaidbill" component={UnPaid} />


                    {localStorage.getItem('role')!=='user' &&   <Route exact path="/viewallbill" component={ViewAllBill} />}

                    {/* <Route exact path="/" component={ViewBill} /> */}

                    <Route path="/event/:id" component={EventDetail} />
                    <Route path="/viewbill/:id" component={ViewBillDetail} />

   
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/tables" component={Tables} />
                    <Route exact path="/maps" component={Maps} />
                    <Route exact path="/pendingbill1stLevel" component={Pending1st} />
                    <Route exact path="/pendingbill12ndLevel" component={Pending2nd} />


                    </> }
                  
                </Switch>
                {/* <Footer /> */}
            </div>
        </>
    );
}

export default App;

import React, { useState, useEffect } from "react";
import { Card, Alert } from "@material-tailwind/react";
import { CardImage } from "@material-tailwind/react";
import { CardBody } from "@material-tailwind/react";
import { CardFooter } from "@material-tailwind/react";
// import {H6} from "@material-tailwind/react/H6";

import { Input } from "@material-tailwind/react";

import axios from "axios";
import { useParams } from "react-router-dom";
import { Modal } from "@material-tailwind/react";
import { ModalHeader } from "@material-tailwind/react";
import { ModalBody } from "@material-tailwind/react";
import { ModalFooter } from "@material-tailwind/react";
export default function Login() {
    const [email,setEmail]=useState("")
    const [err,setErr]=useState("")

    const [passWord,setPassword]=useState("")
    const  addEvent = async () => {
      let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/billlogin",{
          email:email,
          password:passWord,     
      });
      console.log(res.data.data[0])
    if(res.data.data[0]._id){

      localStorage.setItem('userId',res.data.data[0]._id)
      localStorage.setItem('email',res.data.data[0].email)
      localStorage.setItem('role',res.data.data[0].role)
      localStorage.setItem('name',res.data.data[0].name)


      setErr('')

// console.log("resres",res.data)


      setTimeout(() => {
        
           window.location=(`/viewbill`);
        }, 1000);
      
    }
    else{
      setErr('Email id or password wrong')
    }
    
    
  };
  return (
    <div className=" flex items-center justify-center   "style={{minHeight:'100vh'}} >
     <div className="flex items-center justify-center">
      <div className="flex items-center justify-center">
       
        <Card>

        <div className="flex items-center justify-center w-full">
          {/* <Alert   className="my-4 w-full" >Please Login Here </Alert> */}
          </div>
          <div className="flex items-center justify-center w-full">   
          <img
          className="mt-4  "
            src={
              "https://virtuallive.in/assets/img/virtual%20live%20300%20X%20300.png"
            }
            alt="Bill Image"
          />
          </div>

          <CardBody>
              <div className=" items-center justify-center">
              
              <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setEmail(e.target.value)}
                value={email}
                outline={true}
                placeholder="Email"
              />
            </div>
            <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setPassword(e.target.value)}
                value={passWord}
                outline={true}
                placeholder="Password"
              />
            </div>
            <div className="flex items-center justify-center">
            <button
            className="bg-blue-500 text-white border-rounded rounded-xl p-3" 
              onClick={(event) => addEvent()}
              color="lightBlue"
              size="lg"
              ripple="light"
            >
              SUBMIT
            </button> 
            <br />
          
          </div>
          <div className="my-2">
          <a href="/reg" className="text-blue-500 capitalize " >Don't have account ?  click here register </a>
          </div>
          <div className="my-2">
          <div href="/reg" className="text-red-500 capitalize text-center " >{err} </div>
          </div>

              </div>
          
          </CardBody>
        </Card>
      </div>
    </div>
    </div>
  );
}


import axios from "axios";
import { useEffect,useState } from "react";
export default function Dashboard() {
  const [data,setData]=useState([])


  useEffect(()=>{
    const  addEvent = async () => {
      let res = await axios.get("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/getevent");
    if(res.status==200){

      setData(res.data);
      
    }
    
  }
  addEvent()
  },[])
  return (
    <>
    
      <div className="flex  ">
        
       {data.map((item)=>{
         return(<div className="flex flex-col w-4/12 mx-3">
        <div class="w-full bg-white rounded-xl overflow-hdden shadow-md p-4  text-center">
          <div class="p-4 ">
            <div class="p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-md rounded-full bg-red-500">
              <span class="material-icons text-white text-xl leading-none">
                stars
              </span>
            </div>
            <h1 class="text-gray-900 text-xl font-serif font-bold leading-normal mt-0 mb-2">
            {item.eventName}
            </h1>

            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-2 font-bold">
            Managed By {item.eventManager}
             
            </div>
            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-2 font-bold">
            Start Date {item.eventSDate}
             
            </div>
            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-2 font-bold">
            End Date  {item.eventEDate}
             
            </div>
            <div className="flex items-center justify-between">
           

            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-2 font-bold">
            Total spends <br/> 2000
             
            </div>
            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-2 font-bold">
            Total paid <br/>  1000
             
            </div>
            <div class="text-blue-gray-700 text-base leading-relaxed mt-0 mb-2 font-bold">
            Total Left  <br/> 1000
             
            </div>
            </div>
           
          </div>
        </div>
        </div>)})} 
      
      </div>
    </>
  );
}

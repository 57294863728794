import React, { useState, useEffect } from "react";
import {Card} from "@material-tailwind/react";
import {CardImage} from "@material-tailwind/react";
import {CardBody} from "@material-tailwind/react";
import {CardFooter} from "@material-tailwind/react";
// import {H6} from "@material-tailwind/react/H6";


import {Input} from "@material-tailwind/react";


import axios from "axios";
import { useParams } from 'react-router-dom';
import {Modal,Tooltipl} from "@material-tailwind/react";
import {ModalHeader} from "@material-tailwind/react";
import {ModalBody} from "@material-tailwind/react";
import {ModalFooter} from "@material-tailwind/react";
import gifimg from '../assets/img/circle.gif'
import S3FileUpload from 'react-s3';
 import App2 from './test'
//  import  Select  from "@material-tailwind/react";
//  import  Options from "@material-tailwind/react";
//Optional Import
import { Alert } from "@material-tailwind/react";
import ReactS3 from 'react-s3';
// import { H6 } from '@material-tailwind/react/Heading6';
 

export default function Cardss() {
  
  


  const [tab, setTab] = useState("ADD PRODUCT");
  const [tabList, setTabList] = useState(["ADD PRODUCT", "ADD CATEGORY"]);
  const [img2, setImg2] = useState("");
  const [file, setFile] = useState("");
  const [urld, setUrl] = useState("");

  const [category, setCategory] = useState("");
  const [productName, setproductName] = useState("");

  const [productDescription, setproductDescription] = useState("");

  const [productPrice, setproductPrice] = useState("");
  const [quantity, setquantity] = useState("");

  const [amount, setAmount] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [billcycle, setBillCycle] = useState("");
  const [advanced,setAdvanced]= useState("");
  const [showSuc,setShowSec]= useState(false);


  



const { id } = useParams(); // id will be 'amazon'


// userEmail: req.body.userEmail,
//     imagurl: req.body.imagurl.substr(0, req.body.imagurl.indexOf("?")),
//     amount: req.body.amount,
//     eventName: req.body.eventName,
//     productName: req.body.productName,
//     category: req.body.category,
//     productDescription: req.body.productDescription,

//     quantity: req.body.quantity,

  console.log("idididididididididid",id)

  const hit = async (file) => {
    const { url } = await fetch("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/s3Url"
    ).then((res) => res.json());
    console.log("setUrlsetUrlsetUrlsetUrl", url);
  
    setUrl(url);

    // alert(3)

    if (url) {
      const daat = await axios.post(
        "http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/addbill",
        {
          userEmail:localStorage.getItem('email'),
          imagurl: url,
          amount:amount,

          eventName: id,
          productName: productName,

          productDescription: productDescription,
          productPrice: productPrice,
          quantity: quantity,
    vendorName: vendorName,
    vendorEmail:vendorEmail,
    advance:advanced,
    billcycle:billcycle,
    addedBy:localStorage.getItem('email')
    


        }
      );

      console.log(daat);
      if(daat.status==200){
        setShowModal(true)
        setTimeout(() => {
          setShowSec(true)
          // window.location.reload();
        }, 2000);
      }
    }
  };


  const [file2, setFile2] = useState(null);

    // If you are using TypeScript the state will be
    // const [file, setFile] = useState<FileList | null>(null);
   
  
    const submitFile = async () => {
      try {
        if (!file2) {
          throw new Error('Select a file first!');
        }
        const formData = new FormData();
        formData.append('file', file2);
        await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/test-upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // handle success
      } catch (error) {
        // handle error
      }
    }
      
    const config = {
      bucketName :"mice-img",
    
      region :"ap-south-1",
      accessKeyId :'AKIAQY76D4XVE34BX7XG',
      secretAccessKey :'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'
  }
    const  uploadFile=((e)=>{
      S3FileUpload.uploadFile(e,config).then((data)=>{console.log("daat",data)
    
    
        }).catch((error)=>console.log(error))
    
      })
     
      
  
      









      const addcategory= async()=> {
        // POST request using axios with async/await
        const article = {
          
          category: category,
    
          userEmail:localStorage.getItem('email')};
        const response = await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/addcategory', article);
        this.setState({ articleId: response.data.id });
    }
      const hit2 = async (file) => {
        const { url } = await fetch("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/s3Url").then((res) =>
          res.json()
        );
        console.log("setUrlsetUrlsetUrlsetUrl",url);
        setUrl(url)
    
        if(url){
    
    
       const daat=  await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/addbill',{
        userEmail: localStorage.getItem('email'),
        imagurl: url,
        amount:amount,
        eventDate:localStorage.getItem('eventDate'),

        eventName: id,
        productName: productName,
        category:categoryName,
        eventCode:localStorage.getItem('eventCode'),

        productDescription: productDescription,
        productPrice: productPrice,
        quantity: quantity,
  vendorName: vendorName,
  vendorEmail:vendorEmail,
  advance:advanced,
  billcycle:billcycle,
  addedBy:localStorage.getItem('name')

      } )
          
    
       console.log("asasasa",daat.data._id);
       if(daat.data._id)
       {
        setShowModal(true)
        setTimeout(() => {
         
          //  window.location.reload();
        }, 3000);
       }
        }
    
        // post the image direclty to the s3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body:file ,
        });
      };
      useEffect(() => {}, [file])
  
  return (
    <>
    {/* <App2/> */}
{/* 
<div>
      <label>Upload file</label>
      <input type="file" onChange={e => setFile2(e.target.files[0])} />
     
      
    </div>
    <button className="my-6" onClick={()=>uploadFile(file2)} type="submit">Send</button>
<img className="h-10 w-10" src="https://mice-img.s3.ap-south-1.amazonaws.com/a8328f2383ad69f293ba0e087c4a8329" alt="sss" /> */}
    <div className="flex items-center justify-center">
      <div className="lg:w-4/12 sm:w-full  flex items-center justify-center">
        {" "}
        <Card>
        {/* <Alert>Please Fill  with Care</Alert> */}
        {file&&  <CardImage src={file} alt="Bill Image" />}
        <h1 color="gray " className="text-center font-bold " >Event : {id} </h1>

          <CardBody>
            <h1 color="gray text-center w-full" >Bill Amount  </h1>

            <div className={`my-3 border ${amount==""&&'border-red-500'}  `}>
              <Input
                type="text"
                color="lightBlue"
                // className="
                onChange={(e)=>setAmount(e.target.value)}
                value={amount}
                size="lg"
                outline={true}
                placeholder="Enter Bill Amount"
              />
            </div>
            <div className="my-3">
            <input
className='w-100 flex mv2 h2'
        type="file"
        onChange={(event) =>
          event.target.files &&
          (setImg2(event.target.files[0]),
          setFile(URL.createObjectURL(event.target.files[0])))
        }
      />


{/* 
              <Input
                type="file"
                color="lightBlue"
                size="lg"
                outline={true}
                onChange={async ( event) =>
                  event.target.files &&
                  (setImg2(event.target.files[0]),
                  setFile(URL.createObjectURL(event.target.files[0])))
                }
              /> */}
            </div>
           
          
            <div className="my-3  "  style={{width:'100%'}}>


              <select className={`p-2 my-2  border  ${categoryName===""?'border-red-600':'border-gray-500'} `}  
              value={categoryName}
              onChange={(e)=> setCategoryName(e.target.value)}
              name="" id=" " style={{width:'100%'}}>
 <option selected value=""> Select Category </option>

 <option selected value="Fabrication"> Fabrication </option>
 <option selected value="AV Technicals "> AV Technicals </option>
 
 <option selected value="Printing"> Printing </option>
 <option selected value="Gifting / Merchandise /Momentos / Stationary"> Gifting / Merchandise /Momentos / Stationary </option>
 <option selected value="Floral">Floral </option>
 <option selected value="Artist">Artist  </option>
 <option selected value="Manpower">Manpower </option>

 <option selected value="Furnitures"> Furnitures </option>
 <option selected value=" Structures and Hangers"> Structures and Hangers</option> 
 <option selected value="Designing"> Designing </option>
 <option selected value=" Office  Inventory"> Office  Inventory </option>
 <option selected value="Engagement / Games"> Engagement / Games </option>
 <option selected value="Stalls / booths ">Stalls / booths </option>
 <option selected value="Genset"> Genset </option>
 <option selected value="Special Effects ">Special Effects </option>
 <option selected value="Vehicle Rentals"> Vehicle Rentals </option>
 <option selected value="Costume Rentals"> Costume Rentals </option>
 <option selected value="Licences / Permissions"> Licences / Permissions</option>
 <option selected value="Office Supplies"> Office Supplies</option>
 <option selected value="Food for men power"> Food for men power </option>
 <option selected value="Local Travel Allowance"> Local Travel Allowance</option>

 <option selected value=" Miscellaneous Expenses"> Miscellaneous Expenses</option>
                

              </select>

            
            </div>


            <div className="my-3  "  style={{width:'100%'}}>


<select className={`p-2 my-2  border  ${billcycle===""?'border-red-600':'border-gray-500'} `}  
value={billcycle}
onChange={(e)=> setBillCycle(e.target.value)}
 style={{width:'100%'}}>

<option selected value=""> Bill Cycle </option>
<option selected value="30"> 30 Days </option>
<option selected value="15"> 15 Days </option>
<option selected value="7"> 7 Days </option>
<option selected value="immediately">Immediately  </option>

{/* <option selected value="urgent"> urgent </option> */}



  

</select>


</div>

<div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setAdvanced(e.target.value)}
                value={advanced}
                outline={true}
                placeholder="Advance"
              />
            </div>
           
            {/* <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setproductName(e.target.value)}
                value={productName}
                outline={true}
                placeholder="Product Name"
              />
            </div> */}
            <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                onChange={(e)=>setVendorName(e.target.value)}
                value={vendorName}
                size="lg"
                outline={true}
                placeholder="Vendor Name"
              />
            </div>
            <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setVendorEmail(e.target.value)}
                value={vendorEmail}
                outline={true}
                placeholder="Vendor Email"
              />
            </div>
           
          </CardBody>
          <div className="w-72">
    
    </div>

          <div className="flex items-center justify-center">
          {/* <Tooltip content="Material Tailwind"> */}
          {<button
            className={` bg-blue-500 text-white border-rounded p-2 ${(amount=="" || categoryName=="" || billcycle=="" )? 'opacity-30':'opacity-100'} `} 
              onClick={(event) =>  (amount=="" || categoryName=="" || billcycle=="" ) ? null:hit2(img2)}
              color="lightBlue"
          
              size="lg"
              ripple="light"
            >
            {(amount=="" || categoryName=="" || billcycle=="" ) ? ' Fill Basic Detail ':'SUBMIT'}
            </button>}
            {/* </Tooltip> */}
            {showSuc&& <Alert color="green">Product added succesfully</Alert>}
          </div>
        </Card>
      </div>
    </div>





    {/*  */}

<div className="flex items-center justify-center w-4/12">



    <Modal size="sm" active={showModal} toggler={() => setShowModal(false)}>
                <ModalHeader toggler={() => setShowModal(false)}>
                 
                <h1 className="my-3 flex  items-center justify-center text-center"> BILL ADDED </h1>
                </ModalHeader>
                <ModalBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                       <img src={gifimg} alt="" />
                    </p>
                </ModalBody>
              
            </Modal> 
            </div>   </>
  );
}
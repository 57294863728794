import StatusCard from 'components/StatusCard';
import TableCard from 'components/pendingbill12ndLevel';
import {useState,useEffect} from 'react'
import axios from 'axios';
import { Label } from "@material-tailwind/react";
import gifimg from '../assets/img/circle.gif'

import { saveAs } from "file-saver";
import { Modal } from "@material-tailwind/react";
import { ModalHeader } from "@material-tailwind/react";
import { ModalBody } from "@material-tailwind/react";
import { ModalFooter } from "@material-tailwind/react";
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Image from '@material-tailwind/react/Image';
import H5 from '@material-tailwind/react/Heading5';
import Icon from '@material-tailwind/react/Icon';
import LeadText from '@material-tailwind/react/LeadText';
import Button from '@material-tailwind/react/Button';
import ProfilePicture from 'assets/img/team-1-800x800.jpg';
export default function Dashboard() {
const [nameSearch,setNameSearch]=useState("")

    const [showModal, setShowModal] = useState(false);
    const [showModal22, setShowModal22] = useState(false);
  
    const [showModalCode, setShowModalCode] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    const [dataItem, setDataItem] = useState({});
    const [operation, setOperation] = useState(localStorage.getItem('role'));

    const dltBill = async (id) => {

        let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/dltbill", { id: id });
    
        if (res.status == 200) {
          setShowModal22(true)
    
          setTimeout(() => {
    
            window.location.reload();
          }, 2000);
    
    
        }
      }
      const downloadImage = (imgUrl) => {

        // download="AwesomeImage.png"
        saveAs(imgUrl, "bill.jpg"); // Put your image url here.
      };

      const approvedManager = async (paid, status) => {
        let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/appmanager", { id: paid, status: status });
        if (res.status == 200) {
          // window.location.reload();
          setShowModal22(true)
          setShowModalCode(!showModalCode);
          setTimeout(() => {
    
            window.location.reload();
          }, 2000);
        }
      };
      const approvedAsstManager = async (paid, status) => {
        let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/appassttmanager", { id: paid, status: status });
        if (res.status == 200) {
          // window.location.reload();
          setShowModal22(true)
          setShowModalCode(!showModalCode);
          setTimeout(() => {
    
            window.location.reload();
          }, 2000);
        }
      };


  const  datcal=(data)=>{
  
    var timestamp = parseInt(data)
  var date = new Date(timestamp);
  
  console.log("Date: "+date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear()+
            " "+date.getHours()+
            ":"+date.getMinutes()+
            ":"+date.getSeconds());
  
            return (date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear())
  }

const [data,setData]=useState([])
console.log(window.location.pathname)
let loca=window&& window.location.pathname
var str= "12344A56789";
var splitted = loca.split('/viewbill/');

  useEffect(()=>{
    const  addEvent = async () => {
      let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/entirebill");
    if(res.status==200){

      setData(res.data);
      
    }
    
  }
  addEvent()
  },[])
    return (
        <>
        <div className='flex bg-blue-500 items-center justify-center pt-4   '>
  {/* {nameSearch} */}
                <input className='p-2 rounded-xl border border-black w-10/12 lg:w-4/12 md:w-4/12'
                placeholder='search Vendor name'
                type="text" value={nameSearch} onChange={(e)=>setNameSearch(e.target.value)} />
              </div>
             <div className=" pt-14 pb-28 bg-blue-500  md:px-8 h-auto text-center  flex flex-col smhidden"></div>
              <div className=" pt-14 pb-28  md:px-8 h-auto text-center  flex flex-col lghidden">
             
             <div className='flex py-2 rounded-full items-center justify-center bg-blue-500 text-white text-xl text-center tc w-full'>
             Total Bill :{" "}
               { data.totalBill}</div>
               <div className='flex py-2 mt-3 rounded-full items-center justify-center bg-red-500 text-white text-xl text-center tc w-full'>
               Total Pending :{" "}
               { data.totalUnpaid}</div>
               <div className='flex py-2 mt-3 rounded-full items-center justify-center bg-green-500 text-white text-xl text-center tc w-full'>
               Total Paid :{" "}
               { data.paid}</div>
         </div>
            <div className="px-3 md:px-8 h-auto -mt-24 smhidden ">
                <div className="container mx-auto max-w-full ">
                    <div className="grid grid-cols-1 px-4 mb-16 ">
                        <TableCard data={data} nameSearch={nameSearch} />
                    </div>
                </div>
            </div>
            {data &&
                  data.billList &&
                  data.billList.map((item, index) => {return item.vendorName.toLowerCase().includes(nameSearch.toLowerCase())&& item.billStatusManager==="false" && (
                    <div className="px-3 md:px-8 h-auto  lghidden my-3 ">
                    <Card>
                    <div className="flex flex-wrap justify-center">
                    <div className="w-full flex justify-between py-2 lg:pt-4 ">
                          
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                                 {item.sEventDate ||'N/A'}
                              </span>
                              <span className="text-xs text-black-700">Event Date</span>
                          </div>
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                              {item.eventName}
                              </span>
                              <span className="text-xs text-black-700">Event Name	</span>
                          </div>
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                              {item.vendorName}
                              </span>
                              <span className="text-xs text-black-700">Vendor Name 	</span>
                          </div>
                         
                      </div>
                   
                        <div className="w-full flex justify-between py-2 lg:pt-4 ">
                          
                            <div className="py-2 text-center">
                                <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                                   {item.category}
                                </span>
                                <span className="text-xs text-gray-700">category</span>
                            </div>
                            <div className="py-2 text-center">
                                <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                                {item.amount}
                                </span>
                                <span className="text-xs text-gray-700">Total Bill	</span>
                            </div>
                            <div className="py-2 text-center">
                                <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                           {datcal(item.uploadData)}
                                </span>
                                <span className="text-xs text-gray-700"> Upload Date	</span>
                            </div>
                           
                        </div>
        
        
                        <div className="w-full flex justify-between py-2 lg:pt-4 ">
                          
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                                 {item.addedBy}
                              </span>
                              <span className="text-xs text-gray-700">Upload By</span>
                          </div>
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                              {item.billcycle}
                              </span>
                              <span className="text-xs text-gray-700">Bill Cycle	</span>
                          </div>
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                         {(item.advance)}
                              </span>
                              <span className="text-xs text-gray-700"> Advance Paid	</span>
                          </div>
                         
                      </div>
        
        
        
                      <div className="w-full flex justify-between py-2 lg:pt-4 ">
                          
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                              {datcal(item.dueDate)}
                              </span>
                              <span className="text-xs text-gray-700">Due date</span>
                          </div>
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                              {item.amount}
                              </span>
                              <span className="text-xs text-gray-700">Amount 	</span>
                          </div>
                          <div className="py-2 text-center">
                              <span className="text-sm font-medium block uppercase tracking-wide text-gray-900">
                        {(item.eventCode)}
                              </span>
                              <span className="text-xs text-gray-700"> Event Code 	</span>
                          </div>
                         
                      </div>
        
                        <div className="w-full flex justify-between  ">
                          
                          <div className="py-2 text-center">
                          <div className="row flex items-center justify-center text-xs mt-2" >
                          <Button
                            style={{ width: '100%' }}
                            color={item.billStatusManager === "true" ? "green" : 'red'}
                            buttonType="filled"
                            size="regular"
        
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                            {item.billStatusManager === "true" ? "Done" : 'Pending'}
                          </Button>
                          
                        </div>
                        <span className="text-xs text-gray-700">2nd Approver </span>
                        
                             
                          </div>
                          <div className="py-2 text-center">
                          <div className="row flex items-center justify-center cursor-pointer mt-2 " >
                          <Button
                            style={{ width: '100%' }}
                            color={item.billStatusAsstManager === "true" ? "green" : 'red'}
                            buttonType="filled"
                            size="regular"
        
                            rounded={true}
                            block={false}
                            iconOnly={false}
                            ripple="green"
                          >
                            {item.billStatusAsstManager === "true" ? "DONE" : 'PENDING'}
                          </Button>
                        </div>
                              <span className="text-xs text-gray-700">1'St Approver </span>
                          </div>
                          <div className="py-2 text-center">
                          <div className="py-2 text-center ">
                            
                            <span className=" flex text-sm text-gray-700">  {item.billStatus !== "true" ? (
                                  <Label color="red">pending</Label>
                                ) : (
                                  <Label color="green">Paid</Label>
                                )}</span>
                        </div>
                              <span className="text-xs text-gray-700"> Bill Status</span>
                          </div>
                      </div>
                      <div className="w-full flex justify-between  items-center ">
                          
                      <div
                          className="mt-2 text-xs text-center  w-full bg-blue-400 text-white p-1 rounded-xl mt-3 font-semibold cursor-pointer"
        
        
                          // buttonType="filled"
                          // size="small"
                          onClick={() => (
                            setShowModal(true),
                            setImgUrl(item.imagurl),
                            setDataItem(item)
                           
                          )}
                          rounded={true}
                          block={false}
                          iconOnly={false}
                          // disable={true}
                          ripple="light"
                        >
                          View Bill
                        </div>
                        
                          
                          
                      </div>
                    
                    </div>
                
                 
                </Card></div>)})}

                <>
        <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
          <ModalHeader toggler={() => setShowModal(false)}></ModalHeader>
          <ModalBody>
            <img

              className="flex items-center justify-center"
              src={imgUrl}

              download={true}
              alt=""
            />
          



          </ModalBody>
          <div className="flex flex-col items-center justify-center  bg-gray-200 rounded-lg">
           
        
          </div>

         


          {operation == "subadmin" && <div className="flex flex-col items-center justify-center shadow-xl mt-3">
            <ModalFooter>
              <Button
                color="yellow"
                onClick={() => dltBill(dataItem._id)}
                ripple="light"
              >
                Delete Bill
              </Button>
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>

              <Button
                color="red"
                onClick={() => approvedAsstManager(dataItem._id, dataItem.billStatusAsstManager !== "true" ? 'true' : 'false')}
                ripple="light"
              >

                {dataItem.billStatusAsstManager !== "true" ? 'Approve' : 'Reject'}

              </Button>
            </ModalFooter>
          </div>}

          {operation.toLowerCase() == "admin" && <div className="flex flex-col items-center justify-center mt-3">
            <ModalFooter>
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>

              <Button
                color="red"
                onClick={() => approvedManager(dataItem._id, dataItem.billStatusManager !== "true" ? 'true' : 'false')}
                // onClick={() => approvedManager(dataItem._id)}
                ripple="light"
              >
                {dataItem.billStatusManager !== "true" ? 'Approve' : 'Reject'}
              </Button>
            </ModalFooter>
          </div>}

          {operation == "user" && <div className="flex flex-col items-center justify-center">
            <ModalFooter>
              <Button
                color="green"
                onClick={() => downloadImage(imgUrl)}
                ripple="light"
              >
                Download Bill
              </Button>


            </ModalFooter>
          </div>}

        </Modal>
        <div className="flex items-center justify-center w-4/12">



<Modal size="sm" active={showModal22} toggler={() => setShowModal22(false)}>
  <ModalHeader toggler={() => setShowModal22(false)}>

    <h1 className="my-3"> Task  Done </h1>
  </ModalHeader>
  <ModalBody>
    <p className="text-base leading-relaxed text-gray-600 font-normal">
      <img src={gifimg} alt="" />
    </p>
  </ModalBody>

</Modal>

</div>
</>




        </>
    );
}

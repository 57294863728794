
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { useState } from 'react';



import React from 'react'

export default function Data() {
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);
  return (
    <div><DateRange
    editableDateInputs={true}
    onChange={item => setState([item.selection])}
    moveRangeOnFirstSelection={false}
    ranges={state}
  />
   </div>
  )
}

import React,{useEffect,useState} from 'react'
// import Button from "@material-tailwind/react/Button";
import {Card} from "@material-tailwind/react";
import {CardHeader} from "@material-tailwind/react";
import {CardBody} from "@material-tailwind/react";
import {CardFooter} from "@material-tailwind/react";
import {InputIcon} from "@material-tailwind/react";
import {Button} from "@material-tailwind/react";
import {H5} from "@material-tailwind/react";
import axios from 'axios';
export default function Allevent() {


    const [data,setData]=useState([])

  useEffect(()=>{
    const  addEvent = async () => {
      let res = await axios.get("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/getevent");
    if(res.status==200){

      setData(res.data);
      
    }
    
  }
  addEvent()
  },[])

  const handleProceed = (id,eventCode,eventDate) => {
    // alert(eventDate)
    localStorage.setItem('eventCode',eventCode)
    localStorage.setItem('eventDate',eventDate)

    window.location=(`/event/${id.toLowerCase()}`);
  };
  return (
    <div>
      
      
      <div className='flex flex-wrap  items-center justify-center smhidden '>{data.map((item)=>{return(<div
      onClick={()=>(handleProceed(item.eventName,item.eventCode,item.eventSDate))}
      className='md:w-3/12 lg:2-3/12 sm:w-11/12 xl:w-3/12 flex items-center justify-center   text-white  ripple p-2 '>
      <Button
            color="lightBlue"
            buttonType="filled"
            size="lg"
            style={{height:'10rem',width:'100%'}}
            rounded={false}
            block={true}
            iconOnly={false}
            ripple="light"
        >
            
            <h1 className='text-2xl truncate w-11/12'> {item.eventCode}<br/> {item.eventName} </h1>
           
        </Button>


</div>
)})}


      </div>

      <div className='flex   flex-col items-center justify-center m-4  lghidden'>{data.map((item)=>{return(<div
      onClick={()=>(handleProceed(item.eventName,item.eventCode,item.eventSDate))}
      className='w-full flex items-center justify-center   text-white  ripple p-2 '>
      <Button
            color="lightBlue"
            buttonType="filled"
            size="lg"
            style={{height:'10rem',width:'100%'}}
            rounded={false}
            block={true}
            iconOnly={false}
            ripple="light"
        >
            
            <h1 className='text-2xl truncate w-11/12'> {item.eventCode}<br/> {item.eventName} </h1>
           
        </Button>


</div>
)})}


      </div>
      </div>
  )
}

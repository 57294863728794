import React, { useState, useEffect } from "react";
import { Card, Alert } from "@material-tailwind/react";
import { CardImage } from "@material-tailwind/react";
import { CardBody } from "@material-tailwind/react";
import { CardFooter } from "@material-tailwind/react";
// import {H6} from "@material-tailwind/react/H6";
import gifimg from '../assets/img/circle.gif'

import { Input } from "@material-tailwind/react";

import axios from "axios";
import { useParams } from "react-router-dom";
import { Modal } from "@material-tailwind/react";
import { ModalHeader } from "@material-tailwind/react";
import { ModalBody } from "@material-tailwind/react";
import { ModalFooter } from "@material-tailwind/react";
export default function Login() {
    const [email,setEmail]=useState("")
    const [passWord,setPassword]=useState("")
    const [name,setName]=useState("")
    const[msj,  setMsj]=useState("User Added")
  const [showModal, setShowModal] = useState(false);

    // const [name,setName]=useState("")


    // email: req.body.email,
    // password: req.body.password,
    // role: req.body.role,
    // name:req.body.name
    const  addEvent = async () => {
        let res = await axios.post("http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/billreg",{
            email:email,
            password:passWord,
            role:"user",
        
          name:name
        });
        console.log(res.data)
      if( res.data._id){
// alert(8)

        setShowModal(true)
        setMsj("User Added")
        // window.location.reload();
        setTimeout(() => {
          
            window.location=(`/login`);
          }, 2000);
        
      }
      else{
        setShowModal(true)
        setMsj("Email alredy registered plz contact it team ")

      }
      
    };

  return (
    <div className=" flex items-center justify-center  " style={{height:'100vh'}} >
     <div className="flex items-center justify-center">
      <div className="flex items-center justify-center w-full">
       
        <Card>

        <div className="flex items-center justify-center w-full">
          {/* <Alert   className="my-4 w-full" >Please Login Here </Alert> */}
          </div>
          <img
          className="mt-4"
            src={
              "https://virtuallive.in/assets/img/virtual%20live%20300%20X%20300.png"
            }
            alt="Bill Image"
          />

          <CardBody>
              <div className=" items-center justify-center">
              <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setName(e.target.value)}
                value={name}
                outline={true}
                placeholder="Name"
              />
            </div>
              <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setEmail(e.target.value)}
                value={email}
                outline={true}
                placeholder="Email-Id"
              />
            </div>
            <div className="my-3">
              <Input
                type="text"
                color="lightBlue"
                size="lg"
                onChange={(e)=>setPassword(e.target.value)}
                value={passWord}
                outline={true}
                placeholder="Password"
              />
            </div>
            <div className="flex items-center justify-center">
            <button
            className="bg-blue-500 text-white border-rounded rounded-xl p-3" 
              onClick={(event) => addEvent()}
              color="lightBlue"
              size="lg"
              ripple="light"
            >
              SUBMIT
            </button>
          </div>
              </div>
          
              <div className="flex items-center justify-center w-4/12">



<Modal size="sm" active={showModal} toggler={() => setShowModal(false)}>
            <ModalHeader toggler={() => setShowModal(false)}>
             
          
            </ModalHeader>
            <ModalBody>
            <h1 className="my-3 text-center flex item-center  text-2xl text-bold justify-center w-full"> {msj} </h1>
               {msj=="User Added"&& <p className="text-base leading-relaxed text-gray-600 font-normal">
                   <img src={gifimg} alt="" />
                </p>}
            </ModalBody>
          
        </Modal> 
        </div>
          </CardBody>
        </Card>
      </div>
    </div>

   
    </div>
  );
}
